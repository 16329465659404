import { Controller } from '@hotwired/stimulus'
import Rails from "@rails/ujs"

export default class extends Controller {
  static values = {
    url: String,
  }

  static targets = ["priority"]

  // initialize() {}
  // connect() {}

  increase()  {
    const newPriority = parseInt(this.priorityTarget.innerHTML) + 1
    this.priorityTarget.innerHTML = newPriority
    this.updatePriority(newPriority)
  }

  decrease = () => {
    const newPriority = parseInt(this.priorityTarget.innerHTML) - 1
    if (newPriority >= 0) {
      this.priorityTarget.innerHTML = newPriority
      this.updatePriority(newPriority)
    }
  }

  updatePriority = (newPriority) => {
    var data = new FormData();
    data.append("priority", newPriority)

    Rails.ajax({
      type: "patch",
      url: this.urlValue,
      data: data
    })
  }
}
