// Entry point for the build script in your package.json

// Turbo need to be loaded before @rails/ujs
import './turbo_with_prefetch'

import Rails from "@rails/ujs"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()
// ActiveStorage.start()

import './stimulus'
import './on_document_ready'
