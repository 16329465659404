// import initTrackEvents from './track_events'
// import initHasClickableUrls from './has_clickable_urls'
// root specific
import initBulmaUX from './bulma_ux'

// Things to be executed when the page loads
const onDocumentReady = () => {
  initBulmaUX()
  // console.log('onDocumentReady')
  // @ts-ignore
  // gtag('config', 'UA-10949806-18', { 'page_path': window.location.pathname, 'anonymize_ip': true })
  // initPrefetchOnHoverintent()
  // initTrackEvents()
  // initHasClickableUrls()
  // root specific
  // initBulmaUX()
  // Alpine.start()
}

document.addEventListener('turbo:load', onDocumentReady)
// document.addEventListener('turbo:render', onDocumentReady)

// this can be removed once there's an import
// export {}
