import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  // static values = { isOpen: Boolean }
  static targets = ["content"]

  // initialize() {
  //   this.isOpenValue = false
  // }

  toggle = () => {
    // this.isOpenValue = !this.isOpenValue
    this.contentTarget.classList.toggle('hidden')
  }
}
