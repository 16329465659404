// src: Lenns v.2022-04-27

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  // static values = { isOpen: Boolean }
  static targets = ["menu"]

  // initialize() {
  //   this.isOpenValue = false
  // }

  toggle = () => {
    // this.isOpenValue = !this.isOpenValue
    console.log('navbar#toggle')
    this.menuTarget.classList.toggle('mob:hidden')
  }
}
