function supportResponsiveNavbar() {
  // Get all "navbar-burger" elements
  const navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0)

  // Check if there are any navbar burgers
  if (navbarBurgers.length > 0) {
    // Add a click event on each of them
    navbarBurgers.forEach(function (el) {
      el.addEventListener('click', function () {

        // Get the target from the "data-target" attribute
        var target = el.dataset.target;
        target = document.getElementById(target);

        // Toggle the class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        target.classList.toggle('is-active');
      })
    })
  }
}

// to be deleted: 2022-01-12 - deprecated
// function supportClosable() {
//   const selector = '.message > .message-header > button.delete, .notification > button.delete';
//   document.querySelectorAll(selector).forEach((el) => {
//     el.addEventListener('click', (event) => {
//       // @ts-ignore
//       const noticeContainer = event.target.closest('.container--notice')
//       if (noticeContainer) {
//         noticeContainer.remove()
//       } else {
//         // @ts-ignore
//         event.target.closest('.notice').remove()
//       }
//     })
//   })
// }

export default function initBulmaUX() {
  supportResponsiveNavbar()
  // to be deleted: 2022-01-12
  // supportClosable()
}
