// Stimulus
// I cannot make Stimulus play correctly with TypeScript
// const context = require.context("../controllers", true, /\.(js|ts)$/)
// application.load(definitionsFromContext(context))

import { Application } from "@hotwired/stimulus"
window.Stimulus = Application.start()

// Load all specific controllers
import NavbarController from './controllers/navbar_controller'
Stimulus.register("navbar", NavbarController)

import ClosableController from './controllers/closable_controller'
Stimulus.register("closable", ClosableController)

import HasMoreController from './controllers/has_more_controller'
Stimulus.register("has-more", HasMoreController)

import FeedItemController from './controllers/feed_item_controller'
Stimulus.register("feed-item", FeedItemController)

import PriorityToggleController from './controllers/priority_toggle_controller'
Stimulus.register("priority-toggle", PriorityToggleController)
