import { Controller } from '@hotwired/stimulus'
import Rails from "@rails/ujs"

export default class extends Controller {
  static values = {
    id: Number,
  }

  read = () => {
    const readUrl = `/feed_items/${this.idValue}/read`
    Rails.ajax({ type: "post", url: readUrl })
  }

  hide = () => {
    const readUrl = `/feed_items/${this.idValue}/hide`
    Rails.ajax({ type: "post", url: readUrl })
    this.element.remove()
  }
}
